<script>
import SideNav from './SideNav.vue'

export default {
	name: 'Layout',
	components: {
		SideNav
	},
	props: {
		className: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: 'Page'
		}
	}
}
</script>

<template>
	<div id="app-layout" :class="className">
		<side-nav />
		<div class="app-content">
			<h2>{{ title }}</h2>
			<hr />
			<slot />
		</div>
	</div>
</template>

<style scoped lang="scss">
#app-layout {
	display: flex;
	width: 100%;
	position: absolute;

	.app-content {
		flex: 1;
		max-width: calc(100% - 170px);
	}
}
</style>
